import { Injectable } from '@angular/core';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { Auth0Client } from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError, ReplaySubject } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ICustomer, IRole, PermissionNode } from 'src/modules/customer/model/customer.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomerService } from 'src/modules/customer/services/customer.service';
import { async } from '@angular/core/testing';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // @Select(CustomerState.getCustomerRoles) customerRoles$: Observable<IRole[]>;

  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client(environment.auth),
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(() => new Error(err))),
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => {
      this.loggedIn = res;
      if (this.loggedIn) {
        this.getRoles();
      }
    }),
  );
  private urlFromAuth: string;

  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback(this.urlFromAuth))),
    // concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;
  private apiUrl = environment.apiUrl + 'Authenticate';
  private Roles = new ReplaySubject<IRole[]>(1);

  constructor(private router: Router, private http: HttpClient, private customerService: CustomerService) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();

  }


  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser())),
      tap(user => this.userProfileSubject$.next(user)),
    );
  }

  private getRoles() {
    this.http.get<IRole[]>(this.apiUrl + '/Roles').subscribe(data => {
      this.Roles.next(data);
    });
  }

  public hasPermissionObservable(inputNode: PermissionNode): Observable<boolean> {
    const observable = new ReplaySubject<boolean>(1);
    this.Roles.subscribe((roles) => {
      let result = false;
      roles.forEach((item) => {
        if (item.permissions.findIndex(x => x === inputNode) > -1) {
          result = true;
        }
      });
      observable.next(result);
    });
    return observable;
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      }),
    );
    checkAuth$.subscribe();
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        openUrl: async (url) => {
          window.location.replace(url);
        }
        // redirect_uri: `${window.location.origin}`,
        // appState: { target: redirectPath },
      });
    });
  }

  private handleAuthCallback() {
    this.urlFromAuth = window.location.href;
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string; // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([
            this.getUser$(),
            this.isAuthenticated$,
          ]);
        }),
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(([user, loggedIn]) => {
        // Redirect to target route after callback processing
        this.router.navigate([targetRoute]);
      });
    }
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.getIdTokenClaims().then(claims => {
        client.logout({
          clientId: environment.auth.clientId,
          openUrl: (url) => {
            if (url === null || url === undefined || url === '') {
              window.location.replace(claims.logout_url);
            }
            else {
              // window.location.replace(url);
              window.location.replace(url);
            }
          },
          logoutParams: {
            returnTo: 'http://' + location.host,
            federated: true
          }
        });
      });
    });
  }

  // logout() {
  //   // Ensure Auth0 client instance exists
  //   this.auth0Client$.subscribe((client: Auth0Client) => {
  //     // Call method to log out
  //     client.logout({
  //       client_id: environment.auth.client_id,
  //       returnTo: `${window.location.origin}`,
  //     });
  //   });
  // }

  getTokenSilently$(options?): Observable<any> {
    return this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.getTokenSilently(options))));
  }

  getCustomerProfile(): Observable<ICustomer> {
    return this.http.get<ICustomer>(this.apiUrl + '/Customer');
  }
}

