export const environment = {
  production: true,
  apiUrl: 'https://portal.api.rc.cloudfactory.dk/',
  logoUrl: 'https://portal.api.rc.cloudfactory.dk/v2/partners/Partners/{id}/Logo',
  vmConsoleUrl: 'https://terminal.rc.cloudfactory.dk',
  dropboxApiUrl: 'https://portal.api.rc.cloudfactory.dk/dropbox',
  dropboxApiUrlV2: 'https://portal.api.rc.cloudfactory.dk/dropbox/v2',
  catalogueApiUrl: 'https://portal.api.rc.cloudfactory.dk/v2/catalogue/',
  microsoftV2API: 'https://portal.api.rc.cloudfactory.dk/v2/microsoft',


  auth: {
    authorizationParams: {
      audience: 'mylicenses-api-prod',
      redirect_uri: `${window.location.origin}`
    },
    domain: 'login.customerportalauth.com',
    clientId: 'S3h1sBDxToFiiaT2q2ZogqAK9Z1FZLyk',
  },
};
